import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'

/*
import { config, library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS

import { faCircle, faMusic, faPlayCircle, faPauseCircle,
  faHeart as fasHeart, faHeartBroken } from '@fortawesome/free-solid-svg-icons'
import { faRectangleWide } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as farHeart, faComment,
  faVolume as farVolume, faVolumeDown, faVolumeMute,
  faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons'

config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
library.add(faCircle, faMusic, faPlayCircle, faPauseCircle, fasHeart, faHeartBroken,
  faRectangleWide, farHeart, faComment, farVolume, faVolumeDown, faVolumeMute, faAngleUp, faAngleDown)
*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
