import React from 'react'
import axios from 'axios'

//import AudioPlayer from './components/AudioPlayer'
import logo from './img/logo_large_white.png'
import './App.css';

class NowPlayingApp extends React.Component {

  constructor(props) {
    super(props);

    this.eventSource = null;
    this.state = {
      loading: true,
      tracks: [],
      currentPage: 1,
      totalCount: 0,
      totalPages: 1,
      eventsReceived: 0,
    };

    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
  }

  componentDidMount() {
    // load tracks
    axios.get('https://playlist.wkdu.org/api/tracks/page/1')
      .then(res => {
        let tracks = res.data.tracks
        this.setState({
          tracks,
          totalCount: res.data.totalCount,
          totalPages: res.data.totalPages,
          loading: false,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });

    // listen for events
    this.eventSource = new EventSource('https://playlist.wkdu.org/api/events/subscribe');
    this.eventSource.onmessage = (event) => {
      console.log("Success! Updating now playing data.");
      this.addNewTrack(JSON.parse(event.data));
    };
    this.eventSource.onerror = (event) => {
      console.log("Error! Unable to update now playing data.");
      console.log(event);
    };
  }

  componentWillUnmount() {
    if (this.eventSource) this.eventSource.close();
  }

  addNewTrack(track) {
    this.setState({
      tracks: [track, ...this.state.tracks],
      eventsReceived: this.state.eventsReceived + 1,
    });
  }

  handleLoadMoreClick(event) {
    this.loadMoreTracks(this.state.currentPage + 1);
  }

  loadMoreTracks(pageNumber) {
    let offset = this.state.eventsReceived;
    axios.get(`https://playlist.wkdu.org/api/tracks/page/${pageNumber}?offset=${offset}`)
      .then(res => {
        let tracks = res.data.tracks
        this.setState({
          tracks: [...this.state.tracks, ...tracks],
          currentPage: this.state.currentPage + 1,
          totalCount: res.data.totalCount,
          totalPages: res.data.totalPages,
        });
      });
  }

  render() {
    let nowPlayingTrack = this.state.tracks[0] || null;
    let recentlyPlayedTracks = this.state.tracks; //this.state.tracks.slice(1);
    return (
      <div className="App">
        <header>
          <div className="logo">
            <a href="https://wkdu.org" target="_blank" rel="noopener noreferrer">
              <img src={logo} className="logo-img" alt="WKDU Philadelphia 91.7FM Logo" />
            </a>
          </div>
        </header>
        <main className="content">
          <div className="player">

          </div>
          {nowPlayingTrack &&
            <div>
              <h2 className="center">NOW PLAYING</h2>
              <div className="playlist-now">
                <div className="playlist-now-art">
                  <span className="playlist-now-art-cover">
                    <img src="https://placehold.co/150x150"/>
                  </span>
                </div>
                <div className="playlist-now-info">
                  <h3>Artist</h3>
                  <p>
                    {(nowPlayingTrack.type === 'STATION ID' || nowPlayingTrack.type === 'PSA') && (
                      <span>{nowPlayingTrack.type}</span>
                    )}
                    <span>{nowPlayingTrack.artist}</span></p>
                  <h3>Title</h3>
                  <p>{nowPlayingTrack.song}</p>
                  <h3>Album</h3>
                  <p>{nowPlayingTrack.album}</p>
                </div>
              </div>
            </div>
          }
          <div>
            <h2 className="center">Recently Played</h2>
            <table className="playlist-recent table table-striped table-desktop">
              <thead>
                <tr className="table-gray">
                  <th className="col-time">Time</th>
                  <th className="col-artist">Artist</th>
                  <th className="col-track">Song</th>
                  <th className="col-album">Album</th>
                  <th className="col-duration">Length</th>
                </tr>
              </thead>
              <tbody>
                {(this.state.loading && recentlyPlayedTracks.length === 0) && (
                  <tr>
                    <td className="center" colSpan="5">Loading recently played tracks....</td>
                  </tr>
                )}
                {(!this.state.loading && recentlyPlayedTracks.length === 0) && (
                  <tr>
                    <td className="center" colSpan="5">There are no recently played tracks.</td>
                  </tr>
                )}
                {recentlyPlayedTracks.length > 0 && recentlyPlayedTracks.map((track, i) => {
                  if (track.type === 'STATION ID' || track.type ===  'PSA') {
                    let typeClass = (track.type === 'STATION ID') ? 'recent-track-type recent-track-type-id' : 'recent-track-type recent-track-type-psa'
                    return (
                      <tr key={`track${i}`}>
                        <td>{track.playedAt}</td>
                        <td><span className={typeClass}>{track.type}</span></td>
                        <td>{track.song}</td>
                        <td></td>
                        <td className="col-duration">{track.duration}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={`track${i}`}>
                        <td>{track.playedAt}</td>
                        <td>{track.artist}</td>
                        <td>{track.song}</td>
                        <td>{track.album}</td>
                        <td className="col-duration">{track.duration}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
            <table className="playlist-recent table table-striped table-mobile">
              <thead>
                <tr className="table-gray">
                  <th className="col-time">Time</th>
                  <th className="col-artist col-album col-track">
                    <span className="recent-track-artist">Artist</span>
                    <br/>
                    <span className="recent-track-song">Song</span>
                    <br/>
                    <span className="recent-track-album">Album</span>
                  </th>
                  <th className="col-duration">Length</th>
                </tr>
              </thead>
              <tbody>
                {(this.state.loading && recentlyPlayedTracks.length === 0) && (
                  <tr>
                    <td className="center" colSpan="3">Loading recently played tracks....</td>
                  </tr>
                )}
                {(!this.state.loading && recentlyPlayedTracks.length === 0) && (
                  <tr>
                    <td className="center" colSpan="3">There are no recently played tracks.</td>
                  </tr>
                )}
                {recentlyPlayedTracks.length > 0 && recentlyPlayedTracks.map((track, i) => {
                  if (track.type === 'STATION ID' || track.type ===  'PSA') {
                    let typeClass = (track.type === 'STATION ID') ? 'recent-track-type recent-track-type-id' : 'recent-track-type recent-track-type-psa'
                    return (
                      <tr key={`track${i}`}>
                        <td>{track.playedAt}</td>
                        <td>
                          <span className={typeClass}>[{track.type}]</span>
                          <br/>
                          <span className="recent-track-song">{track.song}</span><br/></td>
                        <td className="col-duration">{track.duration}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={`track${i}`}>
                        <td>{track.playedAt}</td>
                        <td>
                          <span className="recent-track-artist">{track.artist}</span>
                          <br/>
                          <span className="recent-track-song">{track.song}</span>
                          <br/>
                          <span className="recent-track-album">{track.album}</span>
                        </td>
                        <td className="col-duration">{track.duration}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
            {(this.state.currentPage < this.state.totalPages) &&
              <div className="load-tracks"><button onClick={this.handleLoadMoreClick}>Load more tracks</button></div>
            }
          </div>
        </main>
        <footer>
          WKDU © {new Date().getFullYear()}
        </footer>
      </div>
    );
  }
}

export default NowPlayingApp;
